import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import PersonIcon from "@material-ui/icons/Person";
import ExitIcon from "@material-ui/icons/ExitToApp";

import keycloak from "../../utils/keycloak";

import "./Account.scss"
import pckg from "../../../package.json"

function logout(config) {
  const confirmed = window.confirm("Are you sure you want to logout?");
  if (confirmed) {
    // Disconnect and redirect to the login page
    keycloak.logout(config);
  }
}

export default function Account(props) {
  const [open, setOpen] = React.useState(false);
  return (
    <List component="nav">
      <ListItem button className="listHeader" onClick={() => setOpen(!open)}>
        <PersonIcon />
        <ListItemText primary={keycloak.getConnectedUserName() || "User"} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem button onClick={() => {logout(props.config);}} >
          <ExitIcon className="exitIcon"/>
          <ListItemText primary={"Logout"} />
        </ListItem>
        <Typography variant="caption" component="h1" align="right" className="package-version">
          {`v${pckg.version} `}
        </Typography>
      </Collapse>
    </List>
  );
}
