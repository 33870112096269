/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import { TextField, Grid, Typography } from "@material-ui/core";

import InternationalFields from "../InternationalFields/InternationalFields";
import ImageInput from "../ImageInput/ImageInput";
import Events from "../Events/Events";
import SelectList from "../SelectList/SelectList";
import DateInput from "../DateInput/DateInput";
import FooterBar from "../FooterBar/FooterBar";
import actions from "../../actions";

import styleConfig from "../../config/style.json";
import allAvailableCountries from "../../available-countries.json";

import "./FeaturedShowForm.scss";

const styles = theme => ({
  content: {
    display: "flex",
    marginLeft: styleConfig.drawerWidth,
    padding: "20px 40px 120px 40px",
    "&.empty": {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      boxSizing: "border-box",
      padding: "0 40px"
    }
  },
  imageGrid: {
    display: "flex"
  }
});

export function FeaturedShowForm({ classes = {}, dispatch, featuredShow, uuid, config, regions }) {
  //Add window listeners
  useEffect(() => {
    window.addEventListener("message", windowMessageReceived);

    return () => {
      window.removeEventListener("message", windowMessageReceived);
    };
  }, []);

  useEffect(() => {
    dispatch(actions.fetchRegions(config));
  }, []);

  const handleChanges = (value, field, language) => {
    dispatch(actions.setField(value, field, language));
  };

  const validateFeaturedShow = () => {
    window.open(
      `${config.APP_TV_WEBSITES_HOST}/en/featured/${featuredShow.code.en}?uuid=${uuid}&validate=1`
    );
  };

  const viewFeaturedShow = () => {
    window.open(
      `${config.APP_TV_WEBSITES_HOST}/en/featured/${featuredShow.code.en}`
    );
  };

  const windowMessageReceived = e => {
    if (e.origin !== config.APP_TV_WEBSITES_HOST) {
      //Message is not from the original source, do not take any action
      return;
    }

    const { validated, uuid: validatedUuid } = e.data;

    if (validated) {
      dispatch(actions.publishFeaturedShow(validatedUuid, config));
    }
  };

  const setStartDate = (date) => {
    dispatch(actions.setStartDate(date));
    if (date._d > new Date(featuredShow.endDate)){
      dispatch(actions.setEndDate(date));
    }
  }

  const setEndDate = (date) => {
    if (date._d >= new Date(featuredShow.startDate)){
      dispatch(actions.setEndDate(date));
    } else {
      dispatch(actions.updateMessage({ type: 'error', message: "End Date cannot be earlier than Start Date" }));
    }
  } 

  if (featuredShow === undefined) {
    return (
      <div className={classnames([classes.content, "empty"])}>
        <Typography variant="h5" component="h1" align="center" className="header">
          Select a featured show from the list on the left
        </Typography>
      </div>
    );
  }

  return (
    <>
      <div className={classes.content}>
        <div>
          {featuredShow !== undefined && (
            <div>
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" component="h1" align="left" className="header">
                    {featuredShow.title.en !== "" ? featuredShow.title.en : "Untitled"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="uuid"
                    label="UUID"
                    type="text"
                    disabled
                    value={uuid || "Will be generated when saved"}
                    className="form-input"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24} style={{ paddingTop: "20px" }}>
                <Grid item xs={12} md={3}>
                  <DateInput
                    date={featuredShow.startDate}
                    onChange={date => setStartDate(date)}
                    label="Start date"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DateInput
                    date={featuredShow.endDate}
                    onChange={date => setEndDate(date)}
                    label="End date"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid className={classes.imageGrid} item xs={12} md={3}>
                  <ImageInput
                    label="Thumbnail"
                    onChange={file => handleChanges(file, "thumbnailImage")}
                    file={featuredShow.thumbnailImage}
                    width={332}
                    height={332}
                    config={config}
                  />
                </Grid>
                <Grid className={classes.imageGrid} item xs={12} md={3}>
                  <ImageInput
                    label="Banner"
                    onChange={file => handleChanges(file, "bannerImage")}
                    file={featuredShow.bannerImage}
                    width={1920}
                    height={371}
                    config={config}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InternationalFields
                    onChange={(value, field, language) =>
                      dispatch(actions.setTitle(value, language))
                    }
                    title="Title"
                    fieldName="title"
                    values={featuredShow.title}
                  />
                  <InternationalFields
                    onChange={handleChanges}
                    title="Subtitle"
                    fieldName="subtitle"
                    values={featuredShow.subtitle}
                  />
                  <InternationalFields
                    onChange={handleChanges}
                    title="Description"
                    fieldName="description"
                    values={featuredShow.description}
                  />
                  <InternationalFields
                    onChange={handleChanges}
                    title="Url"
                    fieldName="code"
                    values={featuredShow.code}
                    config={config}
                  />
                  <InternationalFields
                    onChange={handleChanges}
                    title="Youtube Link"
                    fieldName="youtubeLink"
                    values={featuredShow.youtubeLink}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectList
                    onChange={selected => handleChanges(selected, "countries")}
                    selectedOptions={getFormattedCountries(featuredShow.countries)}
                    title="Countries"
                    subtitle="The featured show will only appear in the selected countries on the right"
                    allOptions={getFormattedCountries()}
                    dispatch={dispatch}
                    groups={getFormattedRegions(regions)}
                    config={config}
                  />
                </Grid>
                <Events
                  events={featuredShow.events}
                  onEdit={events => handleChanges(events, "events")}
                  dispatch={dispatch}
                  config={config}
                />
              </Grid>
            </div>
          )}
        </div>
      </div>
      <FooterBar
        showValidate={uuid}
        onValidate={() => validateFeaturedShow()}
        onSave={async () => dispatch(actions.saveFeaturedShow(featuredShow, uuid, config))}
        onView={() => viewFeaturedShow()}
        config={config}
      />
    </>
  );
}

function getFormattedCountries(countryCodes){
  let formatted = [];
  Object.keys(allAvailableCountries).forEach(function(i) {
    if (countryCodes == null || (countryCodes.length > 0 && countryCodes.includes(allAvailableCountries[i].code))) {
      formatted.push({
        label: `${allAvailableCountries[i].name} (${allAvailableCountries[i].code})`, 
        value: allAvailableCountries[i].code
      });
    }
  });
  return formatted;
}

function getFormattedRegions(regions) {
  let formatted = {};
  if (regions) {
    Object.keys(regions).forEach(function(regionName) {
      formatted[regionName] = { values: regions[regionName].countries };
    });
  }
  return formatted;
}

const mapStateToProps = state => ({
  featuredShow: state.featuredShow,
  uuid: state.uuid,
  regions: state.regions
});

export default withStyles(styles)(connect(mapStateToProps)(FeaturedShowForm));
