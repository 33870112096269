import React from "react";

import { TextField, Typography, Paper, Button, Collapse } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import "./SelectList.scss";

export default function SelectList({
  onChange,
  selectedOptions = [],
  allOptions = [],
  title = "",
  subtitle = "",
  groups
}) {
  const sortedOptions = [...allOptions].sort((a, b) => a.label.localeCompare(b.label));

  const availableOptions = removeSelected(selectedOptions, sortedOptions);

  const onToggle = target => {
    onChange(toggleOption(selectedOptions, target));
  };

  const memorizedList = React.useMemo(() =>  (
    <>
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
      <Typography variant="body1">{subtitle}</Typography>
      <Paper className="select-list-wrapper">
        <Grid container spacing={8}>
          <Grid item sm={12} md={6}>
            <Typography variant="h6">Available countries</Typography>
            <Typography variant="caption">Click on a country from the list below to add it to the selected countries</Typography>
            <SearchableList elements={availableOptions} onClick={onToggle} label="Search in available counties" onChange={onChange} selectedOptions={selectedOptions} groups={groups} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant="h6">Selected countries</Typography>
            <Typography variant="caption">Click on a country from the list below to remove it from the selected countries</Typography>
            <SearchableList elements={selectedOptions} onClick={onToggle} label="Search in selected countries" />
          </Grid>
        </Grid>
        <Button className="clear-selected-btn" variant="contained"  onClick={() => {onChange([])}}>
          Clear all selected countries
        </Button>
      </Paper>
    </>
    ), [selectedOptions]);

  return memorizedList;
}

export function SearchableList({ elements = [], onClick, label = "name", onChange, selectedOptions, groups }) {
  const [searchElements, setSearchElements] = React.useState(elements);
  const [searchCriteria, setSearchCriteria] = React.useState("");
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (searchCriteria) {
      setOpen(false);
    }
    setSearchElements(
      elements.filter(element => {
        return element.label.toUpperCase().includes(searchCriteria.toUpperCase())
      })
    );
  }, [searchCriteria, elements]);

  return (
    <div>
      <TextField
        label={label}
        type="text"
        onChange={event => setSearchCriteria(event.target.value)}
        style = {{width: '90%'}}
      />
      <SearchIcon className="search-button" />
      <div className="list-box">
        {groups && <GroupList groups={groups} onChange={onChange} selectedOptions={selectedOptions} elements={elements} isOpen={open} onCollapse={collapsed => setOpen(collapsed)} />}
        <List className="select-list">
          {searchElements.map(element => {
            return (
              <ListItem key={element.value} onClick={() => { onClick(element); }}>
                <ListItemText primary={element.label} />
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
}

export function GroupList({groups, onChange, selectedOptions, elements, isOpen, onCollapse}) {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen)
  })

  const onCollapseChanged = () => {
    onCollapse(!open);
    setOpen(!open);
  }

  const onGroupSelected = (groupLabel, onChange) => {
    let newSelectedOptions = selectedOptions.map(selectedOption => selectedOption.value);
    groups[groupLabel].values.forEach((value) => {
      const found = elements.find(e => e.value === value);
      if (found !== undefined) {
        newSelectedOptions.push(value);
      }
    });
    onChange(newSelectedOptions);
  }

  let viewItems = [(
    <List className="select-list" key="group-list">
      {Object.keys(groups).map((groupLabel) => {
        if (typeof groups[groupLabel].values !== 'undefined' && groups[groupLabel].values.length > 1){
          return (
            <ListItem key={groupLabel} onClick={() => onGroupSelected(groupLabel, onChange)}>
              <ListItemText primary={groupLabel} className="group"/>
            </ListItem>
          )
        }
        return null;
      })}
    </List>
  )];

  if (viewItems.length > 0) {
    viewItems = [(
      <List key='groups-caption' id="groups-caption" className="groups-caption" onClick={() => onCollapseChanged()}>
        <ListItem className="group-item">
          <ListItemText primary="Country groups (click to collapse / uncollapse)" className="groups-label" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </List>
    )].concat(
        <Collapse in={open} timeout="auto" unmountOnExit key="collapse-groups">
          {viewItems}
        </Collapse>
      );

    viewItems.push((
      <div style={{backgroundColor: 'white'}} key='values-caption' id="values-caption" className="values-caption">
        <Typography
          color="default"
          display="block"
          variant="subtitle1"
        >
          Countries
        </Typography>
      </div>
    ));
  }
  return viewItems;
}

export function removeSelected(selected, data) {
  return selected.length ? data.filter(d => selected.find(selectedItem => d.value === selectedItem.value) === undefined) : data;
}

export function toggleOption(selectedOptions, target) {
  return selectedOptions.find(option => target.value === option.value) !== undefined
    ? selectedOptions.map((item) => {return item.value;}).filter(option => option !== target.value)
    : [...selectedOptions, target].map((item) => {return item.value;}).sort((a, b) => a.localeCompare(b));
}