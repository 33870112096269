import moment from 'moment';
import keycloak from "./utils/keycloak";

const self = {

    updateUuid: (uuid) => {
        return {
            type: 'UPDATE_UUID',
            uuid
        }
    },

    updateFeaturedShow: (featuredShowDatabaseEntry) => {
        // Turn SQL snake_case to camelCase
        const featuredShow = { ...featuredShowDatabaseEntry };
        featuredShow.monthlyTheme = featuredShow.monthly_theme;
        delete featuredShow.monthly_theme;

        return {
            type: 'UPDATE_FEATURED_SHOW',
            featuredShow
        }
    },

    setPublished: (uuid, published) => {
        return {
            type: 'SET_PUBLISHED',
            uuid,
            published
        }
    },

    setValidating: (validating) => {
        return {
            type: 'SET_VALIDATING',
            validating
        }
    },

    setTitle: (value, language) => {
        return {
            type: 'SET_TITLE',
            value,
            language
        }
    },

    setField: (value, field, language) => {
        return {
            type: 'SET_FIELD',
            value,
            field,
            language
        }
    },

    loadFeaturedShows: (featuredShows) => {
        return {
            type: 'LOAD_FEATURED_SHOWS',
            featuredShows
        }
    },


    createFeaturedShow: () => {
        return {
            type: 'CREATE_FEATURED_SHOW'
        }
    },

    clearFeaturedShow: (uuid) => {
        return {
            type: 'CLEAR_FEATURED_SHOW',
            uuid
        }
    },


    setStartDate: (date) => {
        return {
            type: 'SET_START_DATE',
            date: moment(date).format('YYYY-MM-DD')
        }
    },

    setEndDate: (date) => {
        return {
            type: 'SET_END_DATE',
            date: moment(date).format('YYYY-MM-DD')
        }
    },

    setEventField: (value, field, language, index) => {
        return {
            type: 'SET_EVENT_FIELD',
            value,
            field,
            language,
            index
        }
    },


    createEvent: () => {
        return {
            type: 'CREATE_EVENT'
        }
    },

    deleteEvent: (index) => {
        return {
            type: 'DELETE_EVENT',
            index
        }
    },

    updateMessage: (message) => {
        return {
            type: 'UPDATE_MESSAGE',
            message
        }
    },

    hideMessage: () => {
        return {
            type: 'HIDE_MESSAGE'
        }
    },


    setRegions: (regions) => {
        return {
            type: 'SET_REGIONS',
            regions
        }
    },

    fetchRegions: (config) => async (dispatch) => {
        try {
            const response = await fetch(`${config.APP_TV_COUNTRIES_URL}/regions`)
            const responseData = await response.json();
            if (await !response.ok) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }
            dispatch(self.setRegions(responseData))
        } catch(error) {
            dispatch(self.handleError(error.message, "There was an error while fetching the regions", config));
        }
    },

    fetchFeaturedShow: (featuredShow, config) => async (dispatch) => {
        try{
            const response = await fetch(`${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-theme/${featuredShow.uuid}`, {
                method: 'GET',
                headers: keycloak.getKeyCloakHeader()
            });
            
            const responseData = await response.json();
            if (response.status !== 200) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }
    
            dispatch(self.updateFeaturedShow(responseData));
        } catch(error) {
            dispatch(self.handleError(error.message, "There was an error while fetching the featured show's details", config));
        }
    },

    fetchFeaturedShows: (config) => async (dispatch) => {
        try{
            const response = await fetch(`${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-themes/`, {
                method: 'GET',
                headers: keycloak.getKeyCloakHeader()
            });
    
            const responseData = await response.json();
            if (response.status !== 200) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }
    
            dispatch(self.loadFeaturedShows(responseData));
    
        } catch(error) {
            dispatch(self.handleError(error.message, "There was an error while fetching all featured shows", config));
        }
    },

    saveFeaturedShow: (featuredShow, uuid, config) => async (dispatch) => {
        const method = uuid ? 'PATCH' : 'POST';
        const show = {
            uuid,
            monthlyTheme: featuredShow
        };
        try {
            const response = await fetch(uuid ? `${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-theme/${uuid}` : `${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-theme/`, {
                method: method,
                body: JSON.stringify(show),
                headers: {
                    ...keycloak.getKeyCloakHeader(),
                    "Content-type": "application/json"
                }
            });

            const responseData = await response.json();
            if (response.status !== 200) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }

            if (!uuid) {
                dispatch(self.updateUuid(responseData.uuid));
            }

            dispatch(self.updateMessage({ type: 'success', message: `The featured show was succesfully saved` }));
            dispatch(self.fetchFeaturedShows(config));
            dispatch(self.setPublished(uuid, false));
        } catch (error) {
            dispatch(self.handleError(error.message, 'There was an error while saving', config));
        }
    },

    publishFeaturedShow: (uuid, config) => async (dispatch) => {
        dispatch(self.setValidating(true));

        try {
            const response = await fetch(`${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-theme/${uuid}/publish`, {
                method: 'PATCH',
                headers: {
                    ...keycloak.getKeyCloakHeader(),
                    "Content-type": "application/json"
                }
            });

            const responseData = await response.json();
            
            if(response.status !== 200) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }

            dispatch(self.updateMessage({type: 'success', message: `The featured show was successfully updated`}));
            dispatch(self.fetchFeaturedShows(config));
            dispatch(self.setPublished(uuid, true));
            dispatch(self.setValidating(false));
        } catch (error) {
            dispatch(self.handleError(error.message, 'Unable to publish featured show. Please try again', config));
            dispatch(self.setPublished(uuid, false));
            dispatch(self.setValidating(false));
        }
    },

    deleteFeaturedShow: (featuredShow, config) => async (dispatch) => {
        try {
            const response = await fetch(`${config.APP_TV_FEATURED_SHOWS_SERVER}/monthly-theme/${featuredShow.uuid}`, {
                method: 'DELETE',
                headers: {
                    ...keycloak.getKeyCloakHeader(),
                    "Content-type": "application/json"
                }
            });

            const responseData = await response.json();
            if (response.status !== 200) {
                throw new Error(JSON.stringify({status: response.status, message: responseData.errorMessage}));
            }

            dispatch(self.updateMessage({ type: 'success', message: `The featured show was succesfully deleted` }));
            dispatch(self.clearFeaturedShow(featuredShow.uuid));
            dispatch(self.fetchFeaturedShows(config));
        } catch (error) {
            dispatch(self.handleError(error.message, 'There was an error while deleting', config));
        }
    },

    handleError: (errorMessage, defaultMessage, config) => (dispatch) => {
        let error;
        try {
            error = JSON.parse(errorMessage);
        } catch (e) {
            error = {status: 0, message: errorMessage};
        }
        switch(error.status) {
            case 401: 
                keycloak.logout(config);
                break;
            case 403:
                dispatch(self.updateMessage({ type: 'error', message: 'This action is restricted' }));
                break;
            default:
                dispatch(self.updateMessage({ type: 'error', message: defaultMessage }));
        };
    },
}
export default self;