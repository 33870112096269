import React, { useState, useEffect } from "react";

import { Button } from "@material-ui/core";

import "./ImageInput.scss";

import Upload from "asset-uploader";
import "asset-uploader/src/Upload.css";

export default function ImageInput({ label = "", onChange, file = "", width, height, config }) {
  const [displayFile, setDisplayFile] = useState(file === "" || file === undefined);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setDisplayFile(file === "" || file === undefined);
    setErrorMessage("");
  }, [file]);

  const getImageSizeMessage = () => {
    let message = "";
    if (width || height){
      let dimensions = [];
      width && dimensions.push(`width of ${width} pixels`);
      height && dimensions.push(`height of ${height} pixels`);
      message = "Specify an image with " + dimensions.join(" and ");
    }
    return message;
  }

  const memorizedFile = React.useMemo(() => (
      <>
        {!displayFile ? (
          <div className="image-viewer" onClick={() => setDisplayFile(true)}>
            <p className="image-label">{label}</p>
            <img src={file} alt="file" className="thumbnail" />
            <p className="image-hint">Click to edit picture</p>
          </div>
        ) : (
          <div className="image-select-container">
            <span className="image-file">
              <p className="image-label">{label}</p>
              <Upload
                  serviceUrl={config.APP_ASSET_UPLOADER_SERVICE}
                  label={file || label}
                  onUploadSuccess={location => {onChange(location); setErrorMessage("")}}
                  onUploadFail={(errorMessage) => setErrorMessage(errorMessage)} 
                  extensions={[".jpg"]}
                  width={width}
                  height={height}
                  accessToken={`${localStorage.access_token}`}
                />
            </span>
            <span className="image-error">{errorMessage}</span>
            {file ? (
              <Button
                className="image-icon"
                onClick={() => {
                  setDisplayFile(false);
                }}>
                Cancel image modification
              </Button>
            ) : null}
            <p className="image-hint">{getImageSizeMessage()}</p>
          </div>
        )}
      </>
     ), [file, displayFile, errorMessage]);

  return memorizedFile;
}

export const fileFromPath = path => {
  return /[^\\]*$/.exec(path)[0];
};
