import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

export default function DateInput({ date, onChange, label="Date" }) {
  const memorizedDate = React.useMemo(() => (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          margin="none"
          label={label}
          variant="outlined"
          className="date-picker"
          value={date}
          onChange={date => onChange(date)}
        />
      </MuiPickersUtilsProvider>
    ), [date]);
  return memorizedDate;
}
