
import React from 'react';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles'

import actions from "../../actions"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const severityStyles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
});


function CustomSnackbar({snackbar, dispatch, classes}) {

    const handleClose = () => {
        dispatch(actions.hideMessage());
    };

    return(
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleClose}
          className="snack-bar"
        >
          <Content 
            onClose={handleClose}
            variant={snackbar.type}
            message={snackbar.message}
            classes={classes}
          />
        </Snackbar>
    )
}

function Content({ classes, message, onClose, variant}) {
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {Icon === undefined ? null : 
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
          }
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => onClose()}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}
const mapStateToProps = (state) => ({
  snackbar: state.snackbar
});


export default withStyles(severityStyles)(connect(mapStateToProps)(CustomSnackbar));