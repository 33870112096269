import moment from "moment";
import slugify from "@sindresorhus/slugify";

const initialState = {
  snackbar: {
    open: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_FEATURED_SHOWS":
      return {
        ...state,
        featuredShows: action.featuredShows
      };
    case "CREATE_FEATURED_SHOW":
      return {
        ...state,
        featuredShow: newFeaturedShow(),
        uuid: "",
        published: false
      };
    case "CLEAR_FEATURED_SHOW":
      return {
        ...state,
        featuredShow: action.uuid === state.uuid ? undefined : state.featuredShow
      };
    case "UPDATE_FEATURED_SHOW":
      return {
        ...state,
        uuid: action.featuredShow.uuid,
        featuredShow: action.featuredShow.monthlyTheme,
        published: action.featuredShow.published
      };
    case "UPDATE_UUID": {
      return { ...state, uuid: action.uuid };
    }
    case "SET_PUBLISHED":
      if (action.uuid === state.uuid) {
        return {
          ...state,
          uuid: action.uuid,
          published: action.published
        };
      } else {
        return { ...state };
      }
    case "SET_VALIDATING":
      return {
        ...state,
        validating: action.validating
      };
    case "SET_START_DATE":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          startDate: action.date
        },
        featuredShows: state.featuredShows.map(oldFeaturedShow =>
          oldFeaturedShow.uuid === state.uuid
            ? { ...oldFeaturedShow, startDate: action.date }
            : oldFeaturedShow
        )
      };
    case "SET_END_DATE":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          endDate: action.date
        },
        featuredShows: state.featuredShows.map(oldFeaturedShow =>
          oldFeaturedShow.uuid === state.uuid
            ? { ...oldFeaturedShow, endDate: action.date }
            : oldFeaturedShow
        )
      };
    case "SET_TITLE":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          title: {
            ...state.featuredShow.title,
            [action.language]: action.value
          },
          code: {
            ...state.featuredShow.code,
            [action.language]: slugify(action.value)
          }
        },
        featuredShows:
          action.language === "en"
            ? state.featuredShows.map(oldFeaturedShow =>
                oldFeaturedShow.uuid === state.uuid
                  ? {
                      ...oldFeaturedShow,
                      title: { ...state.featuredShow.title, en: action.value }
                    }
                  : oldFeaturedShow
              )
            : state.featuredShows
      };
    case "SET_FIELD":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          [action.field]: action.language
            ? { ...state.featuredShow[action.field], [action.language]: action.value }
            : action.value
        }
      };

    case "SET_REGIONS":
      return {
        ...state,
        regions: action.regions
      }

    //EVENT EDITOR
    case "SET_EVENT_FIELD":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          events: state.featuredShow.events.map((oldEvent, i) =>
            i === action.index
              ? {
                  ...oldEvent,
                  [action.field]: action.language
                    ? {
                        ...oldEvent[action.field],
                        [action.language]: action.value
                      }
                    : action.value
                }
              : oldEvent
          )
        }
      };
    case "CREATE_EVENT":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          events: [...state.featuredShow.events, newEvent()]
        }
      };
    case "DELETE_EVENT":
      return {
        ...state,
        featuredShow: {
          ...state.featuredShow,
          events: state.featuredShow.events.filter(
            (event, index) => index !== action.index
          )
        }
      };
    case "UPDATE_MESSAGE":
      return {
        ...state,
        snackbar: {
          ...action.message,
          open: true
        }
      };

    case "HIDE_MESSAGE":
      return {
        ...state,
        snackbar: {
          open: false
        }
      };

    default:
      return state;
  }
};

function newEvent() {
  return {
    title: newInternationalField(),
    subtitle: newInternationalField(),
    description: newInternationalField(),
    promoLink: newInternationalField()
  };
}

function newFeaturedShow() {
  return {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    title: newInternationalField(),
    description: newInternationalField(),
    code: newInternationalField(),
    subtitle: newInternationalField(),
    youtubeLink: newInternationalField(),
    events: [newEvent()],
    bannerImage: "",
    countries: []
  };
}

function newInternationalField() {
  return {
    en: "",
    fr: "",
    nl: "",
    es: "",
    ko: "",
    de: ""
  };
}

export { reducer, initialState };
