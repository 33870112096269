import React from "react";
import utils from "../../utils/utils"

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToKeycloakLogin = this.redirectToKeycloakLogin.bind(this);
  }

  redirectToKeycloakLogin() {
    //Check if the redirect uri is present or login page itself, change it to home page
    let redirectUri = "";
    if (this.props.location.state) {
      redirectUri = this.props.location.state.redirectUri;
    }
    if (!redirectUri || redirectUri.search("/login") !== -1) {
      redirectUri = window.location.origin + "/";
    }

    //Construct keycloak login url
    const url =
      `${this.props.config.APP_KEYCLOAK_URL}/auth/realms/${this.props.config.APP_KEYCLOAK_REALM}/protocol/openid-connect/auth/?` +
      utils.serializeObject({
        client_id : this.props.config.APP_KEYCLOAK_CLIENT,
        redirect_uri: encodeURIComponent(redirectUri),
        state: `0%2F4028b519-24ef-45e7-b9b4-5b700b871d0c`,
        response_type: "token",
        nonce: "nonce" //the ui does not have any user specific content, no need to validate the nonce
      });

    //Redirect to keycloak login
    window.location.href = url;
  }


  render() {
    return (
      <div>
        {this.redirectToKeycloakLogin(this.props.config)}
        <h2>Redirecting to login page...</h2>
        <p>
          If you are not redirected automatically,{" "}
          <button href="#" onClick={this.redirectToKeycloakLogin}>
            click here
          </button>
        </p>
      </div>
    );
  }
}
