import React from "react";
import SideBar from "../SideBar/SideBar";
import FeaturedShowForm from "../FeaturedShowForm/FeaturedShowForm";
import Snackbar from "../SnackBar/SnackBar";
import { MuiThemeProvider } from "@material-ui/core";

import baseTheme from "../../theme/baseTheme";

import "./Home.scss";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <MuiThemeProvider theme={baseTheme(this.props.config)}>
          <SideBar {...this.props} />
          <FeaturedShowForm {...this.props} />
          <Snackbar {...this.props} />
        </MuiThemeProvider>
      </>
    );
  }
}
