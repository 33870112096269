import React, { Component } from "react";
import Main from "./Main";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { config: null }
  } 

  async componentDidMount() {
    try{
      this.setState({ config: await this.getConfigs() });
    } catch(e) {
      console.error("App encountered an error while getting config", e)
    }
  }

  async getConfigs(){
    if (process.env.NODE_ENV === "production") {
      const response = await fetch("/config");
      const responseData = await response.json();
      return responseData;
    }

    // When developing, we get the environment variables from docker-compose.yml
    // These are not available in production, since environment variables are hard-coded
    // at build-time -- hence, the call to `/config`.
    return {
      APP_SITE: process.env.REACT_APP_SITE,
      APP_TV_FEATURED_SHOWS_SERVER: process.env.REACT_APP_TV_FEATURED_SHOWS_SERVER,
      APP_TV_WEBSITES_HOST: process.env.REACT_APP_TV_WEBSITES_HOST,
      APP_ASSET_UPLOADER_SERVICE: process.env.REACT_APP_ASSET_UPLOADER_SERVICE,
      APP_KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL,
      APP_KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM,
      APP_KEYCLOAK_CLIENT: process.env.REACT_APP_KEYCLOAK_CLIENT,
      APP_KEYCLOAK_AUTHORIZED_USER_ROLE: process.env.REACT_APP_KEYCLOAK_AUTHORIZED_USER_ROLE,
      APP_TV_COUNTRIES_URL: process.env.REACT_APP_TV_COUNTRIES_URL
    }
  }

  render() {
    return this.state.config ? (
      <Main config={this.state.config} />
    ) : (
      <div><h1>Loading configs...</h1></div>
    )
  }
}

export default App;
