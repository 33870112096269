import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import grey from "@material-ui/core/colors/grey";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  CircularProgress,
  MuiThemeProvider
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import styleConfig from "../../config/style.json";
import darkTheme from "../../theme/darkTheme";
import "./FooterBar.scss";

const styles = theme => ({
  appBar: {
    width: `calc(100% - ${styleConfig.drawerWidth})`,
    top: "auto",
    bottom: 0,
    backgroundColor: grey[800]
  },
  toolbar: {
    textAling: "right",
    alignItems: "right",
    justifyContent: "space-between",
    "& button": {
      marginLeft: 15,
      boxShadow: "none"
    }
  },
  toolbarInfo: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    color: amber[600],
    "& h6": {
      marginLeft: 10,
      color: amber[600]
    },
    "&.published": {
      color: green[500],
      "& h6": {
        color: green[500]
      }
    }
  },
  progress: {
    color: amber[800]
  }
});

export function FooterBar({
  classes = {},
  published,
  showValidate,
  validating,
  onSave,
  onValidate,
  onView,
  config
}) {
  return (
    <MuiThemeProvider theme={darkTheme(config)}>
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classnames(classes.toolbarInfo, { published: !!published })}>
            {validating ? (
              <CircularProgress className={classes.progress} size={24} />
            ) : published ? (
              <DoneIcon />
            ) : (
              <EditIcon />
            )}
            <Typography variant="subtitle1">
              {validating ? "Validating..." : published ? "Published" : "Not published"}
            </Typography>
          </div>
          <div>
            {published ? (
              <Button
                variant="outlined"
                className="viewLiveButton"
                onClick={() => onView()}>
                View live
              </Button>
            ) : validating ? null : (
              showValidate ? (
                <Button
                  variant="outlined"
                  className="validateButton"
                  onClick={() => onValidate()}>
                  Preview before publishing
                </Button>
              ) : (
                <Typography 
                  inline
                  variant="subtitle1">
                  You must save before previewing
                </Typography>

              )
            )}
            <Button
              variant="contained"
              className="saveButton"
              color="primary"
              onClick={() => onSave()}>
              Save
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
}

const mapStateToProps = state => ({
  published: state.published,
  validating: state.validating
});

export default withStyles(styles)(connect(mapStateToProps)(FooterBar));
