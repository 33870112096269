import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Home from "./Home/Home";
import Login from "./Login/Login";
import Forbidden from "./Forbidden/Forbidden";

import keycloak from "../utils/keycloak";

class Main extends Component {
  constructor(props) {
    super(props);

    const token = keycloak.getKeycloakToken();
    const isTokenLive = keycloak.isAccessTokenLive(token.access_token);

    this.state = {
      loggedIn: isTokenLive,
      authorized: false,
      token: token.access_token
    };
  }

  componentWillMount() {
    const token = this.getUrlToken();
    if (token.access_token) {
      this.storeAuthToken(token);
      this.setState({
        authorized: keycloak.hasRole(
          token.access_token, 
          this.props.config.APP_KEYCLOAK_CLIENT,
          this.props.config.APP_KEYCLOAK_AUTHORIZED_USER_ROLE
        )
      })
    }
  }

  storeAuthToken(token) {
    this.clearHashParam();
    keycloak.storeKeycloakToken(token);

    this.setState({ 
      loggedIn: true,
    });
  }

  getUrlToken() {
    const access_token = this.getHashValue("access_token") == null ? localStorage.getItem('access_token') : this.getHashValue("access_token");
    const session_state = this.getHashValue("session_state") == null ? localStorage.getItem('session_state') : this.getHashValue("session_state");
    const token_type = this.getHashValue("token_type") == null ? localStorage.getItem('token_type') : this.getHashValue("token_type");
    const expires_in = this.getHashValue("expires_in") == null ? localStorage.getItem('expires_in') : this.getHashValue("expires_in");

    return { access_token, session_state, token_type, expires_in };
  }

  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  }

  clearHashParam() {
    var noHashURL = window.location.href.replace(/#.*$/, "");
    window.history.replaceState("", document.title, noHashURL);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route 
            path="/login" 
            render={() => 
              <Login 
                config={this.props.config} 
                location={{state: {redirectUri: window.location.origin + window.location.pathname}}} 
              />
            }
            push />
          <Route
            path="/"
            render={() =>
              this.state.loggedIn ? (
                this.state.authorized ? <Home config={this.props.config} /> : <Forbidden {...this.props}/>
              ) : (
                <Redirect
                  push
                  from="*"
                  to={{
                    pathname: "/login",
                    state: {
                      redirectUri: window.location.origin + window.location.pathname,
                      config: this.props.config
                    }
                  }}
                />
              )
            }
          />
        </Switch>
      </Router>
    );
  }
}

export default Main;
