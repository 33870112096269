import { createMuiTheme } from "@material-ui/core";

const palettes = {
  classica: {
    primary: { light: "#c8af83", main: "#bb9b65", dark: "#826c46" },
    secondary: { light: "#999999", main: "#808080", dark: "#595959" }
  },
  djazz: {
    primary: { light: "#e74b52", main: "#e21e27", dark: "#9e151b" },
    secondary: { light: "#999999", main: "#808080", dark: "#595959" }
  }
};

export default function baseTheme(config) {
  return createMuiTheme(baseThemeJson(config));
}

export function baseThemeJson(config) {
  return {
    palette: palettes[config.APP_SITE || "classica"],
    typography: {
      useNextVariants: true,
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(",")
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "0.85rem"
        }
      }
    }
  }; 
}

export function getPalette(config) {
  return palettes[config.APP_SITE || "classica"];
}