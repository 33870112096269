import React from "react";

import keycloak from "../../utils/keycloak";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider } from "@material-ui/core";

import darkTheme from "../../theme/darkTheme";
import "./Forbidden.scss";
import pckg from "../../../package.json"

class Forbidden extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={darkTheme(this.props.config)}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className="forbidden"
        >
          <Grid>
            <Typography variant="h1" component="h1">403 Forbidden</Typography>
          </Grid>
          <Grid container direction="column" className="container">
            <Grid>
              <Typography
                variant="subtitle1"
                component="h4"
                color="primary"
              >
                Your account is not authorized to process this action.
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="subtitle1"
                component="h4"
                color="primary"
              >
                Please contact your administrator to request permission.
              </Typography>
            </Grid>
            <Grid>
              <Typography 
                variant="subtitle1" 
                component="p" 
                align="right"
              >
                <Button
                  variant="outlined"
                  onClick={() => { keycloak.logout(this.props.config); }}
                  color="primary"
                >
                  Logout
                </Button>
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="caption" component="h1" className="package-version">
                {`v${pckg.version} `}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default Forbidden;
