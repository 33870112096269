import React from "react";

import { TextField, Grid } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./InternationalFields.scss";
import languages from "../../languages.json";

export default function InternationalFields({
  title,
  values = {},
  fieldName,
  onChange,
  mainLanguage = "en",
  config
}) {
  const memorizedFields = React.useMemo(() => (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            <span className="international-field-title">{title || ""}</span> &nbsp;
            <span className="international-field-value">
              {fieldName === "code" ? `${config.APP_TV_WEBSITES_HOST}/featured/${values[mainLanguage]}` : values[mainLanguage]}
            </span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="international-field-content">
          <Grid item xs={12}>
            {languages.map(language => (
              <LanguageField
                key={language.code}
                value={values[language.code]}
                language={language.name}
                onChange={value => onChange(value, fieldName, language.code)}
              />
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ), [values]);
  return memorizedFields;
}

export function LanguageField({ value = "", language = "", onChange }) {
  const memorizedValue = React.useMemo(
    () => (
      <TextField
        className="language-field"
        variant="outlined"
        label={language}
        type="text"
        value={value}
        onChange={event => onChange(event.target.value)}
        fullWidth
        multiline
      />
    ),
    [value]
  );
  return <div>{memorizedValue}</div>;
}
