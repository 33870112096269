import React from "react";

import { Grid, Paper, Button, Typography, Tooltip } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import ImageInput from "../ImageInput/ImageInput";
import InternationalFields from "../InternationalFields/InternationalFields";
import actions from "../../actions";

import "./Events.scss";

export default function Events({ events = [], dispatch, config }) {
  const memorizedEvents = React.useMemo(
    () => (
      <Grid item xs={12} className="events-wrapper">
        <Typography variant="h5" component="h2">
          Programs / Assets
        </Typography>
        {events.map((event, index) => (
          <EventBlock
            key={index}
            index={index}
            event={event}
            onChange={(value, field, language) =>
              dispatch(actions.setEventField(value, field, language, index))
            }
            onDelete={() => dispatch(actions.deleteEvent(index))}
            config={config}
          />
        ))}
        <Button
          className="add-event-button"
          variant="contained"
          onClick={() => dispatch(actions.createEvent())}>
          Add new program
        </Button>
      </Grid>
    ),
    [events]
  );
  return memorizedEvents;
}

export function EventBlock({ event, onChange, onDelete, config }) {
  const memorizedEvent = React.useMemo(() => {
    if (!event) {
      return null;
    } else {
      return (
        <Grid container>
          <Paper className="event-container">
            <Grid item xs={12}>
              <Typography variant="h6" component="h4">
                {event.title.en !== "" ? event.title.en : "Untitled"}
              </Typography>
              <Tooltip title="Delete program">
                <IconButton
                  aria-label="Delete"
                  className="delete-button"
                  onClick={() => onDelete()}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "10px" }}>
              <ImageInput
                label="Thumbnail"
                onChange={file => onChange(file, "thumbnailImage")}
                file={event.thumbnailImage}
                config={config}
              />
            </Grid>
            <Grid item xs={12}>
              <InternationalFields
                title="Title"
                fieldName="title"
                values={event.title}
                onChange={onChange}
              />
              <InternationalFields
                title="Subtitle"
                fieldName="subtitle"
                values={event.subtitle}
                onChange={onChange}
              />
              <InternationalFields
                title="Description"
                fieldName="description"
                values={event.description}
                onChange={onChange}
              />
              <InternationalFields
                title="Youtube Link"
                fieldName="promoLink"
                values={event.promoLink}
                onChange={onChange}
              />
            </Grid>
          </Paper>
        </Grid>
      );
    }
  }, [event]);
  return memorizedEvent;
}
