import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";

import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Drawer from "@material-ui/core/Drawer";
import Fab from "@material-ui/core/Fab";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import CustomDialog from "../Dialog/CustomDialog";
import actions from "../../actions";
import Account from "../Account/Account";

import darkTheme from "../../theme/darkTheme";
import { getPalette } from "../../theme/baseTheme";
import styleConfig from "../../config/style.json";

import "./SideBar.scss";

const styles = {
  drawer: {
    width: styleConfig.drawerWidth,
    flexShrink: 0
  }
};

function onSelectShow(dispatch, featuredShow, config, lastShow, setLastShow) {
  if (lastShow && lastShow !== featuredShow) {
    let leave = window.confirm(
      "If you leave this page, all the unsaved change will be lost."
    );
    leave && dispatch(actions.fetchFeaturedShow(featuredShow, config));
  } else {
    dispatch(actions.fetchFeaturedShow(featuredShow, config));
  }
  setLastShow(featuredShow);
}

export function SideBar({ dispatch, classes, featuredShows, config }) {
  const [lastShow, setLastShow] = React.useState();
  React.useEffect(() => {
    dispatch(actions.fetchFeaturedShows(config));
  }, []);

  return (
    <MuiThemeProvider theme={darkTheme(config)}>
      <Drawer
        classes={{
          paper: classes.drawer
        }}
        variant="permanent"
        anchor="left">
        <div className="sidebar-container">
          <Account config={config} />
          {featuredShows !== undefined && (
            <FeaturedShowsList
              featuredShows={arrangeByMonth(featuredShows)}
              onSelect={featuredShow =>
                onSelectShow(dispatch, featuredShow, config, lastShow, setLastShow)
              }
              onDelete={featuredShow =>
                dispatch(actions.deleteFeaturedShow(featuredShow, config))
              }
            />
          )}
          <Tooltip title="Create new featured show">
            <Fab
              className="fab"
              size="medium"
              color="primary"
              onClick={() => {
                dispatch(actions.createFeaturedShow());
              }}>
              {featuredShows && featuredShows.length === 0 && (
                <div
                  className="glow"
                  style={{
                    boxShadow: `12px 0 30px 30px ${getPalette(config).primary.light}`,
                    zIndex: `-1`,
                    animation: `blinker 5s linear infinite`
                  }}></div>
              )}
              <AddIcon />
            </Fab>
          </Tooltip>
        </div>
      </Drawer>
    </MuiThemeProvider>
  );
}

export function FeaturedShowsList({ onSelect, onDelete, featuredShows = [] }) {
  return Object.keys(featuredShows).map(month => (
    <ShowsByMonth
      monthsFeaturedShows={featuredShows[month]}
      month={month}
      key={month}
      onSelect={onSelect}
      onDelete={onDelete}
    />
  ));
}

export function ShowsByMonth({ monthsFeaturedShows, month, onSelect, onDelete }) {
  const [open, setOpen] = React.useState(monthsFeaturedShows.open);
  return (
    <List component="nav">
      <ListItem button className="listHeader" onClick={() => setOpen(!open)}>
        <ListItemText primary={month || ""} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ShowsList
          onSelect={onSelect}
          onDelete={onDelete}
          featuredShows={monthsFeaturedShows.featuredShows}
        />
      </Collapse>
    </List>
  );
}

export function ShowsList({ onSelect, onDelete, featuredShows }) {
  return featuredShows.map((featuredShow, index) => (
    <ShowItem
      key={index}
      featuredShow={featuredShow}
      onDelete={onDelete}
      onSelect={onSelect}
    />
  ));
}

export function ShowItem({ featuredShow, onSelect, onDelete }) {
  window.onbeforeunload = () => {
    return "If you leave this page, all the unsaved change will be lost.";
  };

  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = React.useState(false);

  if (!featuredShow) {
    return null;
  }

  return (
    <>
      <CustomDialog
        open={displayDeleteConfirmation}
        title="Delete confirmation"
        message={`Are you sure you want to delete ${featuredShow.title.en ||
          featuredShow.uuid}`}
        onClose={() => setDisplayDeleteConfirmation(false)}
        onConfirm={() => {
          onDelete(featuredShow);
          setDisplayDeleteConfirmation(false);
        }}
      />
      <ListItem button onClick={() => onSelect(featuredShow)}>
        {featuredShow.published ? (
          <DoneIcon style={{ color: green[500] }} />
        ) : (
          <EditIcon style={{ color: amber[600] }} />
        )}
        <ListItemText
          primary={featuredShow.title.en}
          secondary={`${featuredShow.startDate} - ${featuredShow.endDate}`}
        />
        <ListItemSecondaryAction>
          <Tooltip title="Delete featured show">
            <IconButton
              aria-label="Delete"
              onClick={() => setDisplayDeleteConfirmation(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

export function arrangeByMonth(featuredShows) {
  const monthlyFeaturedShows = {};
  featuredShows.sort(function(a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });
  featuredShows.forEach(featuredShow => {
    const startMonth = moment(featuredShow.startDate).format("MMMM YYYY");
    const endMonth = moment(featuredShow.endDate).format("MMMM YYYY");
    const monthLabel =
      startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`;
    if (!monthlyFeaturedShows[monthLabel]) {
      monthlyFeaturedShows[monthLabel] = {
        open: monthLabel === moment().format("MMMM YYYY"),
        featuredShows: []
      };
    }
    monthlyFeaturedShows[monthLabel].featuredShows.push(featuredShow);
  });
  return monthlyFeaturedShows;
}

const mapStateToProps = state => ({
  featuredShows: state.featuredShows,
  lastShow: state.lastShow
});

export default withStyles(styles)(connect(mapStateToProps)(SideBar));
