import jwt from "jsonwebtoken";

const self = {
  //Stores the keycloak token information into local storage
  storeKeycloakToken: token => {
    localStorage.access_token = token.access_token;
    localStorage.token_expires_in = token.expires_in;
    localStorage.session_state = token.session_state;
    localStorage.token_type = token.token_type;
  },
  //Retreives the keycloak token information from local storage
  getKeycloakToken: () => {
    return {
      access_token: localStorage.access_token,
      expires_in: parseInt(localStorage.token_expires_in),
      session_state: localStorage.session_state,
      token_type: localStorage.token_type
    };
  },
  //Validates that an access token has not expired
  isAccessTokenLive: accessToken => {
    const decoded = jwt.decode(accessToken, { complete: true });

    //We can't get the expiry date, something is wrong, the key is obviously not live
    if (!decoded || !decoded.payload || !decoded.payload.exp) {
      return false;
    }

    //Check if key expiration date is before now
    return new Date(decoded.payload.exp * 1000) >= new Date();
  },

  //Returns the authentication header
  getKeyCloakHeader: () => {
    return {
      'Authorization' : `Bearer ${localStorage.access_token}`
    }
  },

  getConnectedUserName: () => {
    try{
      const decoded = jwt.decode(self.getKeycloakToken().access_token, { complete: true });
      const fullname = [
        typeof decoded.payload.given_name !== 'undefined' ? decoded.payload.given_name : null,
        typeof decoded.payload.family_name !== 'undefined' ? decoded.payload.family_name : null
      ].filter(Boolean).join(' ');

      return fullname !== "" ? fullname : decoded.payload.preferred_username;
    } catch(e) {
      return null;
    }
  },

  hasRole: (accessToken, client, role) => {
    if (!accessToken) {
      return false;
    }
    try {
      const decoded = jwt.decode(accessToken, { complete: true });
      return (decoded.payload.resource_access[client].roles.includes(role));
    } catch (e) {
      return false;
    }
  },

  //Clear the local storage's token and redirects to the login page
  logout: async (config) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_expires_in');
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token_expires_in');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token_scope');
    localStorage.removeItem('session_state');
    localStorage.removeItem('token_type');
    document.cookie = 'KEYCLOAK_IDENTITY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'KEYCLOAK_SESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    const redirectUri = window.location.origin + "/login"
    const url =
      `${config.APP_KEYCLOAK_URL}/auth/realms/${config.APP_KEYCLOAK_REALM}/protocol/openid-connect/logout?redirect_uri=${redirectUri}`;
    window.location.href = url;
  },
};

export default self;
